/**
 * @type { import("vue/types/options").ThisTypedComponentOptionsWithRecordProps<import('vue'), { isFormValid: boolean; }, { validateForm(): boolean }, {}, {}> }
 */
export const FormMixin = {
  data() {
    return {
      isFormValid: false
    }
  },
  methods: {
    validateForm() {
      return this.$refs.form.validate()
    }
  }
}
